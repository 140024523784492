<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
<app-loader v-if="isLoading"></app-loader>
<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" />

  <div class="min-height-calc-100-182 max-height-100--60 overflow-y-auto">
    <div class="dashboard-header p-0 box-shadow-none bg-transparent">
      <div class="px-20 px-10-sm">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100-vsm pt-12 pb-16 w-100 border-bottom-normal-1 mb-16">
          <h2 class="font-18 font-w-600 mb-2 mb-sm-0">Segments</h2>
          <div class="d-flex gap-3">
            <kbutton v-if="membersData?.items?.length>0 || isLoading || searchWord || isSearching" :disabled="!hasCreateAccess()"
              @click="createSegment" :class="'h-38 font-w-500'" :theme-color="'primary'">
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 7.5V3.5H7V7.5H3V9.5H7V13.5H9V9.5H13V7.5H9Z" fill="white" />
              </svg>Create Segment
            </kbutton>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-content py-0 px-20 px-10-sm">
      <div v-if="membersData?.items?.length>0 || isLoading || searchWord || isSearching">
        <Grid
          :class="'max-height-100-182 max-height-100-160-md max-height-100-140-vsm mb-60 first-col-checkbox-center table-design-2 th-td-checkbox-style-2 segments-table-wrap'"
          ref="grid" :data-items="membersData.items" :skip="skip" :take="take" :total="total" :pageable="gridPageable"
          :selectable="true" :selected-field="selectedField" :columns="columns" @selectionchange="onSelectionChange"
          @datastatechange="dataStateChange" @headerselectionchange="onHeaderSelectionChange" :sortable="true"
          :sort="sort" @sortchange="sortChangeHandler">
          <GridNoRecords> No segments found. </GridNoRecords>

          <toolbar>
            <div class="d-flex flex-wrap justify-content-between w-100">
              <div class="d-flex w-100-vsm mb-2 mb-sm-0">
                <div class="search-box-wrap width-220 w-100-vsm me-4 mr-0-vsm">
                  <span class="k-icon k-i-zoom"></span>
                  <span class="k-textbox k-grid-search k-display-flex">
                    <k-input :class="'mb-0 width-220 w-100-vsm'" placeholder="Search" :value="searchWord"
                      @input="onSearch">
                    </k-input>
                  </span>
                </div>
              </div>

              <div class="d-flex w-100-vsm">
                <kbutton v-if="selectedItems.length>0" :disabled="!hasDeleteAccess()" :theme-color="'primary'"
                  :fill-mode="'outline'"
                  :class="'w-100-vsm border-normal-1 text-color-red-2 bg-white hvr-bg-red-2 hvr-border-red-2 mt-1px'"
                  :icon="'delete'" @click="deleteModal">
                  Delete
                </kbutton>
              </div>
            </div>
          </toolbar>

          <template v-slot:createdOn="{props}">
            <td>
              <span>{{getCreatedDate(props.dataItem)}}</span>
            </td>
          </template>

          <template v-slot:modifiedOn="{props}">
            <td>
              <div class="d-flex justify-content-between">
                <span v-if="props.dataItem.updatedAt">{{getDate(props.dataItem.updatedAt)}}</span>
                <span v-else>-</span>
              </div>
            </td>
          </template>

          <template v-slot:segmentName="{props}">
            <td>
              <span @click="navigateToSubscribers(props.dataItem)"
                class="font-14 font-w-500 color-blue cursor-pointer">{{isNameLimit(props.dataItem.segmentName)}}</span>
            </td>
          </template>
          <template v-slot:audienceCount="{props}">
            <td>
              <div class="d-flex justify-content-between">
                <span>{{props.dataItem.audienceCount}}</span>
              </div>
            </td>
          </template>

          <template v-slot:actions="{props}">
            <td>
              <AppPopUp v-if="actionsDropDown?.length" :voucher="props.dataItem" :items="actionsDropDown"
                @dropId="onItemClick($event,props.dataItem)" :icon="'more-vertical'" :popUpSettings="popupSettings"
                :buttonClass="'btn-clear'">
              </AppPopUp>
            </td>
          </template>
        </Grid>
      </div>

      <div v-if="membersData?.items?.length==0 && !searchWord && !isSearching && !isLoading"
        class="d-flex flex-column justify-content-center align-items-center min-height-calc-100-182">
        <svg width="216" height="184" viewBox="0 0 216 184" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.05" cx="108" cy="92" r="92" fill="#253858" />
          <g filter="url(#filter0_bdd_5937_157649)">
            <rect x="31.7822" y="53.0176" width="154.563" height="98.2819" rx="1.93432" fill="white" fill-opacity="0.13"
              shape-rendering="crispEdges" />
          </g>
          <g filter="url(#filter1_bdd_5937_157649)">
            <rect x="28.0996" y="43.9238" width="161.928" height="102.965" rx="1.88497" fill="white" fill-opacity="0.13"
              shape-rendering="crispEdges" />
          </g>
          <g filter="url(#filter2_dd_5937_157649)">
            <rect x="23" y="33" width="170" height="108" rx="3.69388" fill="white" />
          </g>
          <rect x="37.25" y="47.25" width="141.5" height="13.5" rx="2.75" fill="url(#paint0_linear_5937_157649)"
            stroke="#0052CC" stroke-width="0.5" />
          <rect x="37.25" y="69.25" width="141.5" height="13.5" rx="2.75" fill="#F3F3F3" stroke="#C7CBDB"
            stroke-width="0.5" />
          <rect x="37.25" y="91.25" width="141.5" height="13.5" rx="2.75" fill="#F3F3F3" stroke="#C7CBDB"
            stroke-width="0.5" />
          <rect x="37.25" y="113.25" width="116.5" height="13.5" rx="2.75" fill="#F3F3F3" stroke="#C7CBDB"
            stroke-width="0.5" />
          <circle cx="173" cy="121" r="10.75" fill="#F6F9FF" stroke="#8C95B6" stroke-width="0.5"
            stroke-dasharray="2 1" />
          <path
            d="M173.556 120.434H176.976V121.58H173.556V125.116H172.402V121.58H168.999V120.434H172.402V116.881H173.556V120.434Z"
            fill="#8C95B6" />
          <defs>
            <filter id="filter0_bdd_5937_157649" x="23.7431" y="44.9784" width="170.641" height="114.36"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.01957" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5937_157649" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="1.81524" />
              <feGaussianBlur stdDeviation="1.81524" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
              <feBlend mode="normal" in2="effect1_backgroundBlur_5937_157649" result="effect2_dropShadow_5937_157649" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="4.01957" />
              <feGaussianBlur stdDeviation="2.00978" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
              <feBlend mode="normal" in2="effect2_dropShadow_5937_157649" result="effect3_dropShadow_5937_157649" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_5937_157649" result="shape" />
            </filter>
            <filter id="filter1_bdd_5937_157649" x="20.3047" y="36.1289" width="177.518" height="118.555"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.89744" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5937_157649" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="1.76009" />
              <feGaussianBlur stdDeviation="1.76009" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
              <feBlend mode="normal" in2="effect1_backgroundBlur_5937_157649" result="effect2_dropShadow_5937_157649" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="3.89744" />
              <feGaussianBlur stdDeviation="1.94872" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
              <feBlend mode="normal" in2="effect2_dropShadow_5937_157649" result="effect3_dropShadow_5937_157649" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_5937_157649" result="shape" />
            </filter>
            <filter id="filter2_dd_5937_157649" x="0.8367" y="18.2245" width="214.327" height="152.327"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="7.38777" />
              <feGaussianBlur stdDeviation="11.0816" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5937_157649" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="1.84694" />
              <feGaussianBlur stdDeviation="1.84694" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_5937_157649" result="effect2_dropShadow_5937_157649" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5937_157649" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_5937_157649" x1="73" y1="43.8889" x2="177.507" y2="50.0259"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#D3DFF6" />
              <stop offset="1" stop-color="#C2D5FD" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <h5 class="font-19 text-color mt-5">No Segment Created.</h5>
        <p class="font-14 color-light2 text-center">
          Set up filters or conditions to segment your target audience <br />
          for sending campaigns.
        </p>
        <kbutton :disabled="!hasCreateAccess()" @click="createSegment" :class="'h-38 font-w-500'"
          :theme-color="'primary'">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 7.5V3.5H7V7.5H3V9.5H7V13.5H9V9.5H13V7.5H9Z" fill="white" />
          </svg>Create Segment
        </kbutton>
      </div>
    </div>

    <EditSegmentsModal v-if="isEditModalOpen" :rowObject="rowObject" @closeEditModal="closeEditModal"
      @showNotificationRename="showNotificationRename"></EditSegmentsModal>
    <DeleteSegmentsModal v-if="isDeleteModalOpen" :ids="rowId" @closeEditModal="closeEditModal"
      @showNotificationDelete="showNotificationDelete">
    </DeleteSegmentsModal>
  </div>
</div>