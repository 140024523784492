<template src="./DeleteSegmentsModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../../common/AppModal/AppModal";
import { removeSegments } from "@/services/clubDetails/segments.service";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    ids: {
      type: String,
      default: "",
    },
  },
  computed: {
    segmentLabel() {
      return this.ids?.split?.(',')?.length > 1 ? 'segments' : 'segment';
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      entityType: +this.$route.query.type,
      isLoader: false
    };
  },
  methods: {
    deleteSegments() {
      this.isLoader = true;
      var model = {
        ids: this.ids,
        clubId: this.$route.params.id,
      };
      removeSegments(model)
        .then((response) => {
          this.isLoader = false;
          this.$emit("showNotificationDelete", response.data.data);
          var model = {
            clubId: this.$route.params.id,
            entityType: this.entityType || 1,
          };
          this.$store.dispatch("getsegmentsLists", model);
          this.modalHandler();
        })
        .catch((error) => {
          this.$emit("showNotificationDelete", false);
          this.isLoader = false;
          this.modalHandler();
        });
    },
    modalHandler(data) {
      this.$emit("closeEditModal", true);
    },
  },
};
</script>
