<modal :title="'Edit Segments'" :handler="true" :cssClass="'c-modal-small-2'" @modalHandler="modalHandler">
    <template v-slot:content>
        <div class="k-form-field">
            <label>Give your Segment a name *</label>
            <k-input :class="'mb-0'" maxlength="75" type="text" v-model="nameModel" @keyup="errors.Name=''" placeholder="Segment name">
            </k-input>
            <span class="k-form-error">{{this.errors?.Name}}</span>
        </div>
    </template>
    <template v-slot:footer>
        <div class="d-flex justify-content-end">
            <div class="me-1">
                <kbutton @click="modalHandler" :class="'flex-none'">Cancel</kbutton>
            </div>
            <div class="ms-1">
                <kbutton :theme-color="'primary'" @click="update" :class="'flex-none'">Update</kbutton>
            </div>
        </div>
    </template>
</modal>