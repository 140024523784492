<app-loader v-if="isLoading || isGetSegmentLoading"></app-loader>
<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
<div id="app" class="dashboard-right-inner">
  <Breadcrumb :type="$route.query.type" :id="$route.params.id" />

  <div class="max-height-100--60 overflow-y-auto">
    <div class="dashboard-header p-0 box-shadow-none bg-transparent">
      <div
        class="d-flex flex-wrap justify-content-between align-items-center py-9 px-20 px-15-sm border-bottom-normal-1">
        <div class="w-100-vsm">
          <h2 class="font-16 font-w-500 mb-2 mb-sm-0">
            {{titleStr}} a segment
          </h2>
          <p class="font-12 color-light2 ms-0 m-2 mb-sm-0">
            Segment your audience by setting up advanced conditions.
          </p>
        </div>
        <div class="w-100-vsm">
          <kbutton @click="cancelSegment" :class="'border-normal-1 text-color'" :theme-color="'primary'"
            :fill-mode="'outline'">Cancel
          </kbutton>
          <kbutton @click="addSegment" :class="'ms-3'" :theme-color="'primary'">
            {{this.$route.query.segmentId?"Update":"Save"}} Segment</kbutton>
        </div>
      </div>
    </div>

    <div class="dashboard-content pt-4 pb-0">
      <form class="bg-white box-shadow-1 py-20 px-20 px-15-sm">
        <div class="w-100 mb-1">
          <label>Give your segment a name</label>
          <div class="k-form-field">
            <k-input v-model="addSegmentsModel.segmentName" :placeholder="'Enter Segment Name'" />
          </div>
        </div>
        <div class="all-segment">
          <div class="segment-group mb-16" v-for="(conditionNew, indexNew) in addSegmentsModel?.segmentGroupList">
            <div v-if="indexNew>0" class="d-flex justify-content-between mb-16">
              <div class="d-flex and-or-buttons">
                <kbutton :theme-color="conditionNew.conditionType=='And'?'primary':'base'" :class="'w-20'" type="Button"
                  @click="segmentGroupListAndCondition(conditionNew,indexNew)">And
                </kbutton>
                <kbutton :theme-color="conditionNew.conditionType=='Or'?'primary':'base'" :class="'w-20'" type="Button"
                  @click="segmentGroupListOrCondition(conditionNew,indexNew)">Or
                </kbutton>
              </div>
              <kbutton v-if="indexNew>0" @click="removeSegmentGroupConditionList($event,conditionNew, indexNew)"
                type="button" :class="'h-38 mb-2'">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13 3H10V2C10 1.45 9.55 1 9 1H6C5.45 1 5 1.45 5 2V3H2V5H3V14C3 14.55 3.45 15 4 15H11C11.55 15 12 14.55 12 14V5H13V3ZM6 3C6.00312 2.99688 6.00313 2.00311 6.00003 2H8.99687L9 2.00312V3H6.00313H6ZM4.00312 14H11V5H10V13H9V5H8V13H7V5H6V13H5V5H4V13.9969L4.00312 14Z"
                    fill="#24243A" />
                </svg>
              </kbutton>
            </div>

            <div class="segment-group-box box-shadow-1 border-normal-2 radius-4 bg-gray-2 p-20">
              <div class="form-row" v-for="(condition, index) in conditionNew?.segmentConditionList" :key="index">
                <div class="d-flex flex-wrap justify-start align-items-top form-group">
                  <div v-if="index>0" class="w-100 mb-16 mt-2">
                    <div class="d-flex and-or-buttons">
                      <kbutton :theme-color="condition.conditionType=='And'?'primary':'base'" :class="'w-20'"
                        type="Button" @click="segmentConditionListAndCondition(condition,index,conditionNew)">And
                      </kbutton>
                      <kbutton :theme-color="condition.conditionType=='Or'?'primary':'base'" :class="'w-20'"
                        type="Button" @click="segmentConditionListOrCondition(condition,index,conditionNew)">Or
                      </kbutton>
                    </div>
                  </div>

                  <dropdownlistWithGroup v-if="condition" :modelValue="condition.segmentConfigId"
                    :class="'me-sm-3 me-1 h-38 mb-2 drop-h-254'" :style="{ width: '230px', minWidth: '18%' }"
                    :placeholder="'Select Data'" :close-on-select="true" :searchable="true"
                    :options="segmentsConfigsData" @change="onChangeMap($event,condition, index)" />

                  <dropdownlist v-if="condition.segmentConfigId > 0" :class="'me-sm-3 me-1 h-38 mb-2'"
                    :style="{ width: '230px', minWidth: '18%' }" :data-items="condition.segmentConfigConditionDDL"
                    :text-field="'alterText'" :data-item-key="'id'" :value="condition.segmentConfigCondition"
                    @change="onChangeSegmentConfigConditionValue($event,condition,index)">
                  </dropdownlist>

                  <div v-if="[11,12].includes(condition.segmentConfigId)">
                    <!-- <pre>
                      {{condition?.segmentConfigCondition?.id}}
                    </pre> -->
                    <k-input v-if="[56,57,58,64,65,66].includes(condition.segmentConfigCondition?.id)"
                      :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      @input="validateNumber($event,condition,'firstValue')" :value="condition.firstValue"
                      :placeholder="'0'" />

                    <kdatetime :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      v-if="[55,59,60,61,63,67,68,69].includes(condition.segmentConfigCondition?.id)"
                      @change="onChangeDatePickerValue($event,condition,'firstValue')"
                      :value="condition.firstValue ? new Date(condition.firstValue): ''">
                    </kdatetime>

                    <span v-if="[61,69].includes(condition.segmentConfigCondition?.id)"
                      :class="'me-sm-3 me-1 mb-2 mt-2 h-38'">And</span>

                    <k-input v-if="[58,66].includes(condition.segmentConfigCondition?.id)"
                      :class="'me-sm-3 me-1 mb-2 hide-arrow h-36'" :style="{ width: '230px', minWidth: '18%' }"
                      :value="condition.secondValue" :placeholder="'0'"
                      @input="validateNumber($event,condition,'secondValue')" />

                    <kdatetime :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      v-if="[61,69].includes(condition.segmentConfigCondition?.id)"
                      :value="condition.secondValue? new Date(condition.secondValue):''"
                      @change="onChangeDatePickerValue($event,condition,'secondValue')">
                    </kdatetime>

                    <dropdownlist v-if="[56,57,58,64,65,66].includes(condition.segmentConfigCondition?.id)"
                      :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      :data-items="dayHoursWeeksYearDropDown" :value="condition.whereConditionType"
                      @change="onChangeDayHoursWeeksYearForCreatedDropdown($event,condition)">
                    </dropdownlist>
                  </div>
                  <div v-else class="d-flex flex-wrap">
                    <k-input v-if="firstValueTextFieldOpen($event,condition, index)" v-model="condition.firstValue"
                      :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      :placeholder="'Start typing'" />

                    <!-- phone Number -->
                    <k-input v-if="[4].includes(condition?.segmentConfigId)" :value="condition.firstValue"
                      :class="'me-sm-3 me-1 mb-2 hide-arrow h-36'"
                      @input="validateNumber($event,condition,'firstValue')"
                      :style="{ width: '230px', minWidth: '18%' }" :placeholder="'0'" />

                    <k-input v-if="firstValueTextFieldNumberOpen($event,condition, index)" :value="condition.firstValue"
                      :class="'me-sm-3 me-1 mb-2 hide-arrow h-36'"
                      @input="validateNumber($event,condition,'firstValue')"
                      :style="{ width: '230px', minWidth: '18%' }" :placeholder="'0'" />

                    <AppDropdownGroup :mode="'tags'" :modelValue="condition?.firstValue?.split(' UNION ALL ')"
                      :class="'me-sm-3 me-1 min-h-36 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      v-if="firstValueMultiSelectFieldOpen($event,condition, index)" :placeholder="'Select Data'"
                      :close-on-select="true" :searchable="true" :options="condition.userDefinedDropdownList"
                      @change="onChangeGetDropdownListValue($event,condition, index)" />

                    <kdatetime :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      v-if="firstValueDatePickerFieldOpen($event,condition, index)"
                      :value="condition.firstValue ? new Date(condition.firstValue): ''"
                      @change="onChangeDatePickerFirstValue($event,condition,index)">
                    </kdatetime>

                    <span v-if="condition?.nextCondition"
                      :class="'me-sm-3 me-1 mb-2 mt-2 h-38'">{{condition.nextCondition}}</span>

                    <k-input v-if="SecondColumnValueTextFieldOpen($event,condition, index)"
                      :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      v-model="condition.secondValue" :placeholder="'Start typing'" />

                    <k-input v-if="SecondColumnValueNumberFieldOpen($event,condition, index)"
                      :class="'me-sm-3 me-1 mb-2 hide-arrow h-36'" :style="{ width: '230px', minWidth: '18%' }"
                      :value="condition.secondValue" :placeholder="'0'"
                      @input="validateNumber($event,condition,'secondValue')" />

                    <kdatetime :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      v-if="secondValueDatePickerFieldOpen($event,condition, index)"
                      :value="condition.secondValue? new Date(condition.secondValue):''"
                      @change="onChangeDatePickerSecondValue($event,condition,index)">
                    </kdatetime>

                    <dropdownlist v-if="segmentChildConditionDropdownOpen($event,condition,index)"
                      :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      :data-items="condition?.segmentChildCondition?.segmentConfigConditionGroupDDL"
                      :text-field="'alterText'" :data-item-key="'id'"
                      :value="condition?.segmentChildCondition?.segmentConfigConditionGroup"
                      @change="onChangeCampaignWorkFlowDropdown($event,condition,index)">
                    </dropdownlist>

                    <k-input v-if="condition?.segmentChildCondition?.isGroupFirstColumn"
                      :class="'me-sm-3 me-1 mb-2 hide-arrow h-36'" :style="{ width: '230px', minWidth: '18%' }"
                      @input="validateNumber($event,condition,'groupFirstValue','segmentChildCondition')"
                      :value="condition.segmentChildCondition.groupFirstValue" :placeholder="'0'" />

                    <span v-if="condition?.segmentChildCondition?.groupNextCondition"
                      :class="'me-sm-3 me-1 mb-2 mt-2 h-38'">{{condition.segmentChildCondition.groupNextCondition}}</span>

                    <k-input v-if="condition?.segmentChildCondition?.isGroupSecondColumn"
                      :class="'me-sm-3 me-1 mb-2 hide-arrow h-36'" :style="{ width: '230px', minWidth: '18%' }"
                      @input="validateNumber($event,condition,'groupSecondValue','segmentChildCondition')"
                      :value="condition.segmentChildCondition.groupSecondValue" :placeholder="'0'" />

                    <dropdownlist v-if="segmentChildConditionDropdownWhereDDLOpen($event,condition,index)"
                      :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      :data-items="condition?.segmentChildCondition?.segmentConfigConditionWhereDDL"
                      :text-field="'alterText'" :data-item-key="'id'"
                      :value="condition?.segmentChildCondition?.segmentConfigConditionWhere"
                      @change="onChangeCampaignWorkFlowSecondDropdown($event,condition,index)">
                    </dropdownlist>

                    <k-input
                      v-if="condition?.segmentChildCondition?.isWhereFirstColumn && !(condition.isWhereFirstValueD || condition.segmentChildCondition?.segmentConfigConditionWhere?.firstValue=='X')"
                      :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      @input="validateNumber($event,condition,'whereFirstValue','segmentChildCondition')"
                      :value="condition.segmentChildCondition.whereFirstValue" :placeholder="'0'" />

                    <kdatetime :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      v-if="segmentChildConditionDropdownWhereFirstValueOpen($event,condition,index)"
                      @change="onChangeDatePickerWhereFirstValue($event,condition,index)"
                      :value="condition?.segmentChildCondition?.whereFirstValue ? new Date(condition.segmentChildCondition.whereFirstValue): ''">
                    </kdatetime>

                    <span
                      :class="'me-sm-3 me-1 mb-2 mt-2 h-38'">{{condition?.segmentChildCondition?.whereNextCondition}}</span>

                    <k-input
                      v-if="condition?.segmentChildCondition?.isWhereSecondColumn && !(condition.isWhereSecondValueDp || condition.segmentChildCondition?.segmentConfigConditionWhere?.lastValue=='X')"
                      :class="'me-sm-3 me-1 mb-2 hide-arrow h-36'" :style="{ width: '230px', minWidth: '18%' }"
                      @input="validateNumber($event,condition,'whereSecondValue','segmentChildCondition')"
                      :value="condition.segmentChildCondition.whereSecondValue" :placeholder="'0'" />

                    <kdatetime :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      v-if="segmentChildConditionDropdownWhereSecondValueOpen($event,condition,index)"
                      :value="condition.segmentChildCondition.whereSecondValue ? new Date(condition.segmentChildCondition.whereSecondValue):''"
                      @change="onChangeDatePickerWhereSecondValue($event,condition,index)">
                    </kdatetime>

                    <dropdownlist
                      v-if="(condition?.segmentChildCondition?.isWhereSecondColumn||condition?.segmentChildCondition?.isWhereFirstColumn) && !(condition.isWhereSecondValueDp || condition.segmentChildCondition?.segmentConfigConditionWhere?.lastValue=='X') && !(condition.isWhereFirstValueD || condition.segmentChildCondition?.segmentConfigConditionWhere?.firstValue=='X')"
                      :class="'me-sm-3 me-1 h-38 mb-2'" :style="{ width: '230px', minWidth: '18%' }"
                      :data-items="dayHoursWeeksYearDropDown"
                      :value="getDayHoursWeeksYearDropdown($event,condition,index)"
                      @change="onChangeDayHoursWeeksYearDropdown($event,condition,index)">
                    </dropdownlist>
                  </div>

                  <kbutton v-if="conditionNew?.segmentConditionList.length>1"
                    @click="removeSegmentConditionList($event,condition, index, indexNew)" type="button"
                    :class="'h-38 mb-2'">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 1.41562L6.41563 5L10 8.58438L8.58438 10L5 6.41563L1.41562 10L0 8.58438L3.58437 5L0 1.41562L1.41562 0L5 3.58437L8.58438 0L10 1.41562Z"
                        fill="#24243A" />
                    </svg>
                  </kbutton>
                </div>
              </div>

              <div class="new-condition">
                <kbutton @click="addConditions(indexNew)" type="button" :class="''" :theme-color="'primary'"
                  :fill-mode="'outline'">
                  + New Condition
                </kbutton>
              </div>
            </div>
            <div v-if="addSegmentsModel?.segmentGroupList.length==(indexNew+1)" class="new-group mt-26">
              <kbutton @click="addNewConditions(indexNew+1)" type="button" :class="''" :theme-color="'primary'">
                + New Group
              </kbutton>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<CreateUpdateSegmentModal v-if="isModalOpenForCreateSegment"></CreateUpdateSegmentModal>