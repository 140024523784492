<modal :isLoading="isLoader" :title="'Delete Segment'" :handler="true" :cssClass="'c-modal-small-2'" @modalHandler="modalHandler">
    <template v-slot:content>
        <div>
            <p class="font-16 m-0 py-4 mb-0 text-center">
                Are you sure you want to delete selected {{segmentLabel}}?
            </p>
        </div>
    </template>
    <template v-slot:footer :class="'justify-content-end modal-footer'">
        <div class="d-flex justify-content-between">
            <div class="me-1 w-50-p">
                <kbutton @click="modalHandler" :class="'w-100'">Cancel</kbutton>
            </div>
            <div class="ms-1 w-50-p">
                <kbutton :theme-color="'primary'" @click="deleteSegments" :class="'w-100'">Delete</kbutton>
            </div>
        </div>
    </template>
</modal>