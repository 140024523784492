<template src="./EditSegmentsModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../../common/AppModal/AppModal";
import { renameList } from "../../../../../../services/clubDetails/list.service";

import { renameSegment } from "@/services/clubDetails/segments.service";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    rowObject: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      errors: [
        {
          Name: ""
        }
      ],
      entityType: +this.$route.query.type,
      nameModel: this.rowObject?.segmentName
    };
  },
  computed: {
    listDetails() {
      return this.$store.getters.listDetails;
    },
  },
  methods: {
    update() {
      if (this.nameModel.trim() == "" || this.nameModel.trim() == null) {
        this.errors.Name = "Segment name is required.";
        return;
      }
      var payload = {
        id: this.rowObject?.id,
        segmentName: this.nameModel.trim(),
        clubId: this.clubId,
        entityType: this.entityType,
      };
      renameSegment(payload)
        .then((response) => {

          if (response?.data?.data?.status == "error") {
            this.errors.Name = "Segments name already exists.";
            return;
          }
          this.$emit("showNotificationRename", response.data.data);
          var model = {
            clubId: this.$route.params.id,
            entityType: this.entityType || 1,
          };
          this.$store.dispatch("getsegmentsLists", model);

          this.modalHandler();
        })
        .catch((error) => {
          // this.errors = error.response.data.errors;
          this.$emit("showNotificationRename", false);
        });
    },
    modalHandler(data) {
      this.$emit("closeEditModal", true);
    },
  },
};
</script>
