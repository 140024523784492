<template src="./Segments.html"></template>
<style lang="scss">
@import "./Segments.scss";
</style>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import EditSegmentsModal from "./modals/EditSegmentsModal/EditSegmentsModal.vue";
import DeleteSegmentsModal from "./modals/DeleteSegmentsModal/DeleteSegmentsModal.vue";
import CreateUpdateSegment from "./CreateEditSegment/CreateUpdateSegment.vue";
import GetMembersModel from "../../../../models/member/getMembersModel";
import AppLoader from "../../../common/AppLoader/AppLoader";
import moment from "moment";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar.vue";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";
export default {
  name: "Segments",
  components: {
    AppPopUp,
    Breadcrumb,
    buttongroup: ButtonGroup,
    Grid: Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    dropdownlist: DropDownList,
    DropDownButton,
    EditSegmentsModal,
    Tooltip,
    DeleteSegmentsModal,
    CreateUpdateSegment,
    AppLoader,
    AppSnackBar,
    GridNoRecords,
  },
  data() {
    return {
      itemTagTypes: {
        1: "dpAudience",
        2: "ecAudience",
        3: "vAudience",
      },
      moduleTagTypes: {
        1: "digitalPass",
        2: "emailCampaign",
        3: "voucher",
      },
      childItemCodeType: {
        1: "dpSegments",
        2: "ecSegments",
        3: "vSegments",
      },
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      clubId: this.$route.params.id,
      selectedField: "selected",
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      selectedItems: [],
      documentIdPass: "",
      selectedMembers: [],
      selectedMemberList: [],
      actions: [],
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      rowId: "",
      rowObject: [],
      isSearching: false,
      searchWord: "",
      timer: null,
      forceSelectAll: false,
      allChecked: false,
      isNotify: false,
      notifyMessage: "Success",
      notifyType: "success",
      actionsDropDown: [],
      sort: [],
      sortBy: "",
      sortDir: "",
    };
  },
  computed: {
    part() {
      return this.$route.query.part;
    },
    areAllSelected() {
      return (
        this.selectedItems?.length == this.membersData?.items?.length &&
        this.membersData?.items?.length != 0
      );
    },
    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
          width: "42px",
        },
        {
          field: "segmentName",
          title: "Name",
          cell: "segmentName",
          sortable: false,
        },
        {
          field: "audienceCount",
          title: "Total Members",
          cell: "audienceCount",
          sortable: true,
        },
        // {
        //   field: "activeCampaigns",
        //   title: "Active Campaigns",
        //   cell: "activeCampaigns",
        //   sortable: false,
        // },
        {
          field: "createdOn",
          title: "Created On",
          cell: "createdOn",
          sortable: false,
        },
        {
          field: "modifiedOn",
          title: "Modified On",
          cell: "modifiedOn",
          sortable: false,
        },
        // {
        //   field: "RecentlyJoined",
        //   title: "Recently Joined",
        // },
        {
          field: "actions",
          title: "Action",
          cell: "actions",
          sortable: false,
          width: "90px",
        },
      ];
    },
    membersData() {
      if (this.forceSelectAll) {
        let membersData = this.$store.state.segments.segmentsLists.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.rejectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = false;
            } else {
              membersData[key].selected = true;
            }
          }
        }

        return this.$store.state.segments.segmentsLists;
      } else {
        let membersData = this.$store.state.segments.segmentsLists.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = true;
            } else {
              membersData[key].selected = false;
            }
          }
        }
        return this.$store.state.segments.segmentsLists;
      }
    },
    total() {
      return this.membersData ? this.membersData.size : 0;
    },
    take() {
      return this.membersData ? this.membersData.limit || 100 : 100;
    },
    skip() {
      return this.membersData ? this.membersData.offset : 0;
    },
    isLoading() {
      return this.$store.state.segments.isLoading;
    },
    fetchController() {
      return this.$store.state.segments.fetchController;
    },
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    this.actions = [
      ...(this.hasEditAccess() ? [{ id: 0, text: "Edit" }] : []),
      ...(this.hasEditAccess() ? [{ id: 2, text: "Rename" }] : []),
      ...(this.hasDeleteAccess() ? [{ id: 3, text: "Delete" }] : []),
    ];
    this.actionsDropDown = [
      ...(this.hasEditAccess() ? [{ dropId: 1, text: "Edit" }] : []),
      ...(this.hasEditAccess() ? [{ dropId: 2, text: "Rename" }] : []),
      ...(this.hasDeleteAccess() ? [{ dropId: 3, text: "Delete" }] : []),
    ];
    this.part;
    this.getSegmentsLists();
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    getDate(date) {
      var result = moment(date).format("DD-MMM-YYYY");
      return result;
    },
    getSegmentsLists() {
      let payload = {
        clubId: this.$route.params.id,
        searchQuery: "",
        entityType: this.$route.query.type || 1,
        limit: 100,
      };
      this.$store.dispatch("getsegmentsLists", payload);
    },

    getSegmentFormat(props) {
      return props.dataItem?.activeCampaigns.split(",", 2);
    },

    getCreatedDate(date) {
      if (date != null) {
        return moment(date?.createdOn).format("DD-MMM-YYYY");
      }
    },

    onHeaderSelectionChange(event) {
      this.allChecked = event.event.target.checked;
      this.membersData.items = this.membersData.items.map((item) => {
        return { ...item, selected: this.allChecked };
      });
      if (this.allChecked) {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
            } else {
              this.selectedItems.push(membersData[key].id);
            }
          }
        }
      } else {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              let selectedData = this.selectedItems;
              for (const j in selectedData) {
                if (Object.hasOwnProperty.call(selectedData, j)) {
                  if (selectedData[j] === membersData[key].id) {
                    selectedData.splice(j, 1);
                  }
                }
              }
            }
          }
        }
      }
    },

    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      if (this.forceSelectAll) {
        if (event.dataItem.selected === false) {
          this.rejectedItems.push(event.dataItem.id);
        } else {
          if (this.rejectedItems.indexOf(event.dataItem.id) !== -1) {
            let rejectedData = this.rejectedItems;
            for (const key in rejectedData) {
              if (Object.hasOwnProperty.call(rejectedData, key)) {
                if (rejectedData[key] === event.dataItem.id) {
                  rejectedData.splice(key, 1);
                }
              }
            }
            this.rejectedItems = rejectedData;
          }
        }
      } else {
        if (event.dataItem.selected === true) {
          this.selectedItems.push(event.dataItem.id);
        } else {
          if (this.selectedItems.indexOf(event.dataItem.id) !== -1) {
            let selectedData = this.selectedItems;
            for (const key in selectedData) {
              if (Object.hasOwnProperty.call(selectedData, key)) {
                if (selectedData[key] === event.dataItem.id) {
                  selectedData.splice(key, 1);
                }
              }
            }
            this.selectedItems = selectedData;
          }
        }
      }
    },

    dataStateChange(event) {
      this.isSearching = true;
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        clubId: this.$route.params.id,
        searchQuery: this.searchWord,
        limit: event.data.take,
        offset: event.data.skip,
        entityType: this.$route.query.type || 1,
      };
      this.$store.dispatch("getsegmentsLists", payload);
    },

    navigateToSubscribers(e) {
      this.$router.push({
        name: "EcSegmentDetails",
        query: {
          type: +this.$route.query.type,
          segmentId: e.id,
          segmentName: e.segmentName,
          count: e.audienceCount,
        },
        params: {
          ...this.$route.params,
          segmentId: e.id,
          segmentName: e.segmentName,
        },
      });
    },

    // onItemClick(e, data) {
    //   if (e.item.text == "Edit") {
    //     this.$router.push({
    //       name: "EcSegmentEdit",
    //       query: {
    //         type: +this.$route.query.type,
    //         segmentId: data.id,
    //         segmentName: data.segmentName,
    //       },
    //     });
    //   }
    //   if (e.item.text == "Delete") {
    //     this.isDeleteModalOpen = true;
    //     this.rowId = data.id;
    //   }
    //   if (e.item.text == "Rename") {
    //     this.isEditModalOpen = true;
    //     this.rowObject = data;
    //   }
    // },

    onItemClick(dropId, data) {
      if (dropId == 1) {
        this.$router.push({
          name: "EcSegmentEdit",
          query: {
            type: +this.$route.query.type,
            segmentId: data.id,
            segmentName: data.segmentName,
          },
        });
      }
      if (dropId == 3) {
        this.isDeleteModalOpen = true;
        this.rowId = data.id;
      }
      if (dropId == 2) {
        this.isEditModalOpen = true;
        this.rowObject = data;
      }
    },

    closeEditModal(e) {
      if (e) {
        this.isEditModalOpen = false;
        this.isDeleteModalOpen = false;
      }
    },

    showNotificationRename(e) {
      if (e) {
        if (e?.status == "error") {
          this.showNotification("Segments name already exists.", "warning");
        } else
          this.showNotification("Segments name has been updated.", "success");
      }
      // else {
      //   this.showNotification("Something went wrong", "warning");
      // }
    },

    showNotificationDelete(e) {
      if (e) {
        this.selectedItems = [];
        this.showNotification(
          e?.message,
          e?.status == "error" ? "warning" : "success"
        );
      }
      // else {
      //   this.showNotification(
      //     "You cannot delete this segment because one or more segment are in use by your campaign and email template.",
      //     "warning"
      //   );
      // }
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        7000
      );
    },

    deleteModal() {
      this.rowId = this.selectedItems;
      this.rowId = this.rowId.join();
      this.isDeleteModalOpen = true;
    },
    payloadGenerator() {
      let payload = new GetAudienceModel();
      payload = {
        ...payload,
        clubId: this.clubId,
        searchText: this.searchWord,
        entityType: +this.$route.query.type,
      };
      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }
      return payload;
    },
    refetchSegments(resetPagination = false) {
      let payload = this.payloadGenerator();

      if (resetPagination) {
        payload = {
          ...payload,
          limit: this.take,
          offset: 0,
        };
      } else {
        payload = {
          ...payload,
          limit: this.take,
          offset: this.skip,
        };
      }
      this.$store.dispatch("getsegmentsLists", payload);
    },
    sortChangeHandler: function (e) {
      this.isSearching = true;

      this.sort = e.sort;
      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      this.refetchSegments(true);
    },

    onSearch(e) {
      this.isSearching = true;
      this.searchWord = e.value;
      this.$store.commit("SET_SEGMENTS_LOADER", true);

      let payload = {
        clubId: this.$route.params.id,
        searchQuery: this.searchWord ? this.searchWord : null,
        limit: this.take,
        // offset: this.skip,
        entityType: this.$route.query.type || 1,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getsegmentsLists", payload);
      }, 800);
    },
    isNameLimit(name) {
      return name.length <= 50 ? name : name.substring(0, 50) + "...";
    },

    createSegment() {
      this.$router.push({
        name: "EcSegmentCreate",
        query: {
          type: +this.$route.query.type,
        },
      });
    },
  },
};
</script>

<style></style>
